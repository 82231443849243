.overlay-navigation {
  border-top: 2px solid var(--p500);
  width: 100%;
  height: 0;
  transition: all .2s;
  position: fixed;
  right: -100%;
  z-index: 999;
  color: var(--p400);
}

.overlay-navigation.active {
  border-top: 2px solid var(--s500);
  background-color: #000000bf;
  height: 100%;
  right: 0;
}

.overlay-navigation ul {
  display: flex;
  gap: 0;
  flex-direction: column;
  padding-inline-start: 0;
  list-style-type: none;
}

.overlay-navigation ul li {
}

.overlay-navigation ul li a {
  display: block;
  padding: 1rem 2rem;
  font-size: var(--font-xs);
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 0 0 5px black;
  letter-spacing: 1px;
}
